<template>
	<div class="i-surveys-result">
		<div class="i-surveys-result-main">
			<div class="main-icon">
				<img
					class="main-icon--inner"
					src="@/img/icon-result-success.png"
					alt=""
				/>
			</div>
			<p class="main-title" v-if="$route.query.status == 'submit'">提交成功</p>
			<p class="main-title" v-else>暂存成功</p>
		</div>

		<base-footer>
			<van-button type="primary" block round @click="clickDone">完 成</van-button>
		</base-footer>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
		...mapState("surveys", ["pageTitle", "surveysTitle"]),
	},
	created() {
    this.SET_PAGE_TITLE(this.surveysTitle)
	},
	methods: {
    ...mapMutations('surveys', ['SET_PAGE_TITLE', 'SET_SURVEYS_TITLE']),
		clickDone() {
      this.$router.go(-1);
			// this.$router.push({
			// 	path: "/i-surveys/result-list",
			// 	query: this.$route.query,
			// });
		},
	},
};
</script>
